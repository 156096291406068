<template>
  <div class="taxas">
    <div class="grid-box">
      <Breadcrumb :levels="breadcrumbLevels" />
      <div class="main-block footer">
        <v-layout>
          <div class="boxapp">
            <div class="boxapp-head">
              <a href="#" @click="$router.go(-1)">
                <faIcon
                  type="fal"
                  name="long-arrow-left"
                  size="24"
                  colorFont="#cfd2dd"
                />
              </a>
              <h1>Edição de Taxas</h1>
            </div>
            <div class="box-main">
              <div
                v-for="paymentMethodProvider in paymentMethodProviders"
                :key="paymentMethodProvider.id"
              >
                <h3>{{ paymentMethodProvider.name }}</h3>
                <div class="section">
                  <v-layout row mw-half>
                    <v-flex>
                      <InputMoney
                        v-if="has('taxBoleto', paymentMethodProvider)"
                        v-model="paymentMethodProvider.configManager.taxBoleto"
                        textLabel="Taxa de transação via boleto"
                        :precision="2"
                      />
                    </v-flex>
                  </v-layout>

                  <!-- <v-layout row mw-half>
                    <v-flex>
                      <InputMoney
                        v-if="has('taxCreditCard', paymentMethodProvider)"
                        v-model="
                          paymentMethodProvider.configManager.taxCreditCard
                        "
                        textLabel="Taxa de Cartão de Crédito para transação única"
                        :precision="2"
                        prefix="% "
                      />
                    </v-flex>
                  </v-layout>

                  <v-layout row mw-half>
                    <v-flex>
                      <InputMoney
                        v-if="
                          has('taxRecurrentCreditCard', paymentMethodProvider)
                        "
                        v-model="
                          paymentMethodProvider.configManager
                            .taxRecurrentCreditCard
                        "
                        textLabel="Taxa de Cartão de Crédito para transação recorrente"
                        :precision="2"
                        prefix="% "
                      />
                    </v-flex>
                  </v-layout> -->
                  <div v-if="paymentMethodProvider.name == 'PagarMe'">
                    <br/>
                    <div class="mw-half">
                        <p>
                           <strong>Doação à vista</strong>
                        </p>
                        <InputMoney
                            v-model="receiverParcelament[0].singleTransactionValue"
                            textLabel="Recebedor D+2"
                            :precision="2"
                            prefix="% "
                        />
                        <InputMoney
                            v-model="receiverParcelament[12].singleTransactionValue"
                            textLabel="Recebedor D+30"
                            :precision="2"
                            prefix="% "
                        />
                    </div>
                    <br/>
                    <div class="mw-half">
                        <p>
                            <strong>Parcelamento D+30</strong> 
                        </p>
                        <InputMoney
                            v-model="receiverParcelament[13].recurrentTransactionValue"
                            textLabel="2 a 6x"
                            :precision="2"
                            prefix="% "
                        />
                        <InputMoney
                            v-model="receiverParcelament[18].recurrentTransactionValue"
                            textLabel="7 a 12x"
                            :precision="2"
                            prefix="% "
                        />
                    </div>
                    <br/>
                    <div class="mw-half">
                        <p>
                            <strong>Parcelamento D+2</strong> 
                        </p>
                        <InputMoney
                            v-model="receiverParcelament[1].recurrentTransactionValue"
                            textLabel="2x"
                            :precision="2"
                            prefix="% "
                        />
                        <InputMoney
                            v-model="receiverParcelament[2].recurrentTransactionValue"
                            textLabel="3x"
                            :precision="2"
                            prefix="% "
                        />
                        <InputMoney
                            v-model="receiverParcelament[3].recurrentTransactionValue"
                            textLabel="4x"
                            :precision="2"
                            prefix="% "
                        />
                        <InputMoney
                            v-model="receiverParcelament[4].recurrentTransactionValue"
                            textLabel="5x"
                            :precision="2"
                            prefix="% "
                        />
                        <InputMoney
                            v-model="receiverParcelament[5].recurrentTransactionValue"
                            textLabel="6x"
                            :precision="2"
                            prefix="% "
                        />
                        <InputMoney
                            v-model="receiverParcelament[6].recurrentTransactionValue"
                            textLabel="7x"
                            :precision="2"
                            prefix="% "
                        />
                        <InputMoney
                            v-model="receiverParcelament[7].recurrentTransactionValue"
                            textLabel="8x"
                            :precision="2"
                            prefix="% "
                        />
                        <InputMoney
                            v-model="receiverParcelament[8].recurrentTransactionValue"
                            textLabel="9x"
                            :precision="2"
                            prefix="% "
                        />
                        <InputMoney
                            v-model="receiverParcelament[9].recurrentTransactionValue"
                            textLabel="10x"
                            :precision="2"
                            prefix="% "
                        />
                        <InputMoney
                            v-model="receiverParcelament[10].recurrentTransactionValue"
                            textLabel="11x"
                            :precision="2"
                            prefix="% "
                        /><InputMoney
                            v-model="receiverParcelament[11].recurrentTransactionValue"
                            textLabel="12x"
                            :precision="2"
                            prefix="% "
                        />
                    </div>
                    <br/>
                </div>
                  <v-layout row mw-half>
                    <v-flex>
                      <InputMoney
                        v-if="has('taxAccountDebit', paymentMethodProvider)"
                        v-model="
                          paymentMethodProvider.configManager.taxAccountDebit
                        "
                        textLabel="Taxa de Débito em conta para transação única"
                        :precision="2"
                        prefix="% "
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout row mw-half>
                    <v-flex>
                      <InputMoney
                        v-if="
                          has('taxRecurrentAccountDebit', paymentMethodProvider)
                        "
                        v-model="
                          paymentMethodProvider.configManager
                            .taxRecurrentAccountDebit
                        "
                        textLabel="Taxa de Débito em conta para transação recorrente"
                        :precision="2"
                        prefix="% "
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout row mw-half>
                    <v-flex>
                      <InputMoney
                        v-if="has('taxPix', paymentMethodProvider)"
                        v-model="paymentMethodProvider.configManager.taxPix"
                        textLabel="Taxa do Pix"
                        :precision="2"
                        prefix="% "
                      />
                    </v-flex>
                  </v-layout>
                </div>
              </div>
            </div>
          </div>

          <div class="box-help-spacer"></div>
          <HelpBox :text="helpTextBox" />
        </v-layout>

        <div class="footer-step">
          <v-btn
            flat
            round
            color="white"
            class="btn-primary flex-inherit px-5 ml-auto"
            large
            @click="save"
            >Salvar</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script type="plain/text">
import HeroInner from "@/components/HeroInner.vue";
import { Routes } from "@/scripts/models/enums/routes.enum";
import PaymentMethod from "@/scripts/models/enums/paymentMethod.enum";
import { HelpBoxText } from "@/scripts/models/enums/helpBoxText.enum.js";
import HelpBox from "@/components/HelpBox.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ConfigManagerService from "@/scripts/services/configManager.service.js";
import ReceiverParcelament from "@/scripts/services/receiverParcelament.service.js";
import ConfigManager from "@/scripts/models/configManager.model.js";
import CommonHelper from "@/scripts/helpers/common.helper";

export default {
  components: {
    HeroInner,
    HelpBox,
    HelpBoxText,
    Breadcrumb,
  },
  data: () => ({
    configManagerService: new ConfigManagerService(),
    receiverParcelamentService: new ReceiverParcelament(),
    paymentMethodProviders: [],
    receiverParcelament: [],
    breadcrumbLevels: [{ text: "Início" }, { text: "Configurações" }],
    helpTextBox: HelpBoxText.Taxes,
  }),
  created() {
    this.load();
  },
  methods: {
    load() {
      this.configManagerService
        .listAllPaymentMethodProvider()
        .then(this.paymentMethodProviderCallback);

      this.receiverParcelamentService.listAll().then(res => {
        if (res) {
          this.receiverParcelament = res;
        }
      })
    },
    save() {
      this.configManagerService.savePaymentMethodProvider(
        this.callbackSave,
        this.paymentMethodProviders
      );
      this.receiverParcelamentService.saveReceiverParcelament(this.receiverParcelament);
    },
    has(method, obj) {
      switch (method) {
        case "taxPix":
          return obj.paymentMethodPaymentMethodProviders.some(
            (el) => el.paymentMethodId == PaymentMethod.Pix
          );
          break;
        case "taxBoleto":
          return obj.paymentMethodPaymentMethodProviders.some(
            (el) => el.paymentMethodId == PaymentMethod.Ticket
          );
          break;
        case "taxRecurrentCreditCard":
        case "taxCreditCard":
          return obj.paymentMethodPaymentMethodProviders.some(
            (el) => el.paymentMethodId == PaymentMethod.CreditCard
          );
          break;
        case "taxRecurrentAccountDebit":
        case "taxAccountDebit":
          return obj.paymentMethodPaymentMethodProviders.some(
            (el) => el.paymentMethodId == PaymentMethod.AccountDebit
          );
          break;
        default:
          break;
      }
    },
    paymentMethodProviderCallback(data) {
      this.paymentMethodProviders = data;
      this.paymentMethodProviders.forEach((el) => {
        if (el.configManager == null) el.configManager = new ConfigManager();
      });
    },
    callbackSave(data) {
      CommonHelper.swal("", "Taxas atualizadas com sucesso!", "success");
      this.load();
    },
  },
};
</script>